import './About.css';

import * as Icon from 'react-bootstrap-icons';
import ProfilePic from './assets/NicklasBehrndtz_Billede_Kvadrat.png';
import ButtonHollow from './components/ButtonHollow.js';
import ButtonHome from './components/ButtonHome.js';

import { lazy, Suspense } from 'react';
import ViewportLoader from './components/ViewportLoader.js';

const Header = lazy(() => import('./AboutHeader.js'))
const Education = lazy(() => import('./AboutEducation.js'))
const Skills = lazy(() => import('./AboutSkills.js'))
const Portfolio = lazy(() => import('./AboutPortfolio.js'))
const Footer = lazy(() => import('./Footer.js'))

const About = () =>
{ 
  return (
    <>
      <ViewportLoader height={"80vh"} color='blue'>
        <Header />
      </ViewportLoader>

      <Suspense>
        <ViewportLoader height="1200px" viewMargin='400px'>
          <Education />
        </ViewportLoader>
      </Suspense>

      <ViewportLoader height="840px" color='rgb(0, 121, 121)' viewMargin='400px'>
        <Skills />
      </ViewportLoader>

      <ViewportLoader height="80vh" viewMargin='400px'>
        <Portfolio />
      </ViewportLoader>

      <ViewportLoader height="30vh" viewMargin='400px'>
        <Footer />
      </ViewportLoader>
    </>
  )
}

export default About