import React, { Suspense, useEffect } from 'react'
import { useInView } from 'react-intersection-observer';

function ViewportLoader({ children, width = "100%", height, color = "transparent", viewMargin = "100px", style })
{
    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
        rootMargin: viewMargin,
        triggerOnce: true,
    });

    return (
        <>
            {inView ?
                <Suspense fallback={<div ref={ref} style={{...style, width: width, height: height, background: color }} />} >
                    {children}
                </Suspense>
                :
                <div ref={ref} style={{...style, width: width, height: height, background: color }} />
            }
        </>
    )
}

export default ViewportLoader