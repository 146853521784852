import './Home.css';
import ButtonHollow from './components/ButtonHollow.js';
import * as Icon from 'react-bootstrap-icons';
import { Controller, useSpring, animated, config, easings } from '@react-spring/web'

function Animation()
{

}

function Home()
{
    const [fade1, api1] = useSpring(() => ({
        from: { opacity: 0, x: 100 }
    }));

    const [fade2, api2] = useSpring(() => ({
        from: { opacity: 0, x: -100, color: '#2749ba' }
    }));

    const [fade3, api3] = useSpring(() => ({
        from: { opacity: 0, transform: 'translate3D(0, -5.0px, 0) scale(1.05)' },
        config: { mass: 10, tension: 200, frequency: 1, damping: 0.2 }
    }));

    setTimeout(() =>
    {
        api1.start({
            to: { opacity: 1, x: 0 }
        })
    }, 500);
    setTimeout(() =>
    {
        api2.start({
            to: { opacity: 1, x: 0 }
        })
    }, 800);
    setTimeout(() =>
    {
        api3.start({
            to: { opacity: 1, transform: 'translate3D(0, 0, 0) scale(1.0)' }
        })
    }, 1800);

    const [bounce1, b1] = useSpring(() => ({ from: { transform: 'scale(1.0)' }, config: {mass: 20, duration: 3000, easing: easings.easeInOutSine} }));
    const [bounce2, b2] = useSpring(() => ({ from: { transform: 'scale(1.0)' }, config: {mass: 20, duration: 3000, easing: easings.easeInOutSine} }));
    const [bounce3, b3] = useSpring(() => ({ from: { transform: 'scale(1.0)' }, config: {mass: 20, duration: 3000, easing: easings.easeInOutSine} }));

    setTimeout(() =>
    {
        b1.start({
            from: { transform: 'scale(1.0)' },
            to: { transform: 'scale(1.1)' },
            loop: { reverse: true },
        })
    }, 50);
    
    setTimeout(() =>
    {
        b2.start({
            from: { transform: 'scale(1.0)' },
            to: { transform: 'scale(1.1)' },
            loop: { reverse: true },
        })
    }, 100);
    
    setTimeout(() =>
    {
        b3.start({
            from: { transform: 'scale(1.0)' },
            to: { transform: 'scale(1.1)' },
            loop: { reverse: true },
        })
    }, 150);

    // LAYOUT
    return (
        <div id='background'>
            <div id='hero'>
                <animated.div className='title' style={fade1}>Nicklas</animated.div>
                <animated.div className='title' style={fade2}>Behrndtz</animated.div>
                <animated.div className='subTitle' style={fade3}>LET'S POWER THROUGH!</animated.div>

                <ButtonHollow href={'about'}>Who am I? <Icon.ArrowUpRightSquareFill style={{ margin: ' 0px 10px' }} size={25} /></ButtonHollow>
            </div>

            {/* Background circles */}
            <animated.div className='circleBlue' style={bounce1}></animated.div>
            <animated.div className='circleBlue2' style={bounce2}></animated.div>
            <animated.div className='circleBlue3' style={bounce3}></animated.div>
        </div>
    )
}

export default Home;